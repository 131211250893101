<template>
  <div class="row">
    <div class="col-md-12">
      <h4 class="title">Gestione Invii</h4>
    </div>

    <div class="col-12">
      <b-overlay
        id="overlay-background"
        :show="!hasLoaded"
        fixed
        no-wrap
        variant="light"
        opacity="0.85"
        blur="5px"
      >
      </b-overlay>
      <b-modal ref="my-modal" hide-footer title="Annullamento Invio Sollecito">
        <div class="d-block text-center">
          <h3>Sicuro di voler annullare la programmazione di questo sollecito?</h3>
        </div>
        <b-button class="mt-3" variant="outline-danger" style="backgroud-color: red;" block @click="hideModal">Chiudi</b-button>
        <b-button class="mt-2" variant="outline-warning" style="backgroud-color: green;" block @click="cancelReminder">Annula Sollecito</b-button>
      </b-modal>
      <card>
        <div>
          <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
            <el-select
              class="select-default mb-3"
              style="width: 200px"
              v-model="pagination.perPage"
              placeholder="Per pagina">
              <el-option
                class="select-default"
                v-for="item in perPageOptions"
                :key="item"
                :label="item"
                :value="item">
              </el-option>
            </el-select>
          </div>
          <div class="col-sm-12" v-if="hasLoaded">
            <el-table stripe
                      style="width: 100%;"
                      :data="queriedData"
                      border>
              <el-table-column v-for="column in tableColumns"
                               :key="column.label"
                               :min-width="column.minWidth*2/3"
                               :prop="column.prop"
                               :label="column.label">
              </el-table-column>
              <el-table-column
                :min-width="120"
                fixed="right"
                label="Azioni">
                <template slot-scope="props">
                  <a v-tooltip.top-center="'Visualizza Dettaglio'" class="btn-info btn-simple btn-link"
                    @click="handleView(props.$index, props.row)"><i
                    class="fa fa-image"></i></a>
                  <a v-tooltip.top-center="'Annulla'" class="btn-danger btn-simple btn-link"
                     @click="handleDelete(props.$index, props.row)"><i class="fa fa-times"></i></a>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
          <div class="">
            <p class="card-category">Da {{from + 1}} a {{to}} di {{total}} invii</p>
          </div>
          <l-pagination class="pagination-no-border"
                        v-model="pagination.currentPage"
                        :per-page="pagination.perPage"
                        :total="pagination.total">
          </l-pagination>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
  import { Table, TableColumn, Select, Option } from 'element-ui'
  import {Pagination as LPagination} from 'src/components/index'
  import ReminderService from 'src/services/ReminderService'
  import Vue from 'vue'
  import { ModalPlugin } from 'bootstrap-vue'
  Vue.use(ModalPlugin)
  import { ButtonPlugin } from 'bootstrap-vue'
  Vue.use(ButtonPlugin)

  export default {
    components: {
      LPagination,
      [Select.name]: Select,
      [Option.name]: Option,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn
    },
    computed: {
      pagedData () {
        return this.tableData.slice(this.from, this.to)
      },
      /***
       * Searches through table data and returns a paginated array.
       * Note that this should not be used for table with a lot of data as it might be slow!
       * Do the search and the pagination on the server and display the data retrieved from server instead.
       * @returns {computed.pagedData}
       */
      queriedData () {
        let result = this.tableData
        if (this.searchQuery !== '') {
          result = this.fuseSearch.search(this.searchQuery)
          this.pagination.total = result.length
        }
        return result.slice(this.from, this.to)
      },
      to () {
        let highBound = this.from + this.pagination.perPage
        if (this.total < highBound) {
          highBound = this.total
        }
        return highBound
      },
      from () {
        return this.pagination.perPage * (this.pagination.currentPage - 1)
      },
      total () {
        this.pagination.total = this.tableData.length
        return this.tableData.length
      },
      perPageOptions: function () {
        let a = this.tableData.length / 5
        let b = this.tableData.length % 5
        if (a >= 10 || a >= 9 && b > 0) {
          this.pagination.perPageOptions = [5, 10, 25, 50]
          return [5, 10, 25, 50]
        } else if (a >= 5 || a >= 4 && b > 0) {
          this.pagination.perPageOptions = [5, 10, 25]
          return [5, 10, 25]
        } else if (a >= 2 || a >= 1 && b > 0) {
          this.pagination.perPageOptions = [5, 10]
          return [5, 10]
        } else {
          this.pagination.perPageOptions = [5]
          return [5]
        }
      }
    },
    data () {
      return {
        hasLoaded: false,
        pagination: {
          perPage: 5,
          currentPage: 1,
          perPageOptions: [5, 10, 25, 50],
          total: 0
        },
        searchQuery: '',
        propsToSearch: ['name', 'email', 'age'],
        tableColumns: [
          {
            prop: 'template',
            label: 'Sollecito',
            minWidth: 250
          },
          {
            prop: 'company',
            label: 'Cliente',
            minWidth: 200
          },
          {
            prop: 'start_date',
            label: 'Data di inizio',
            minWidth: 250
          },
          {
            prop: 'progress',
            label: 'Progresso',
            minWidth: 250
          },
          {
            prop: 'ended',
            label: 'Terminato',
            minWidth: 250
          },
          {
            prop: 'items_uploaded',
            label: 'Elementi Caricati',
            minWidth: 250
          }
        ],
        tableData: [],
        fuseSearch: null,
        cancel_reminder_id: null
      }
    },
    methods: {
      handleLike (index, row) {
        alert(`Your want to like ${row.first_name}`)
      },
      handleEdit (index, row) {
        alert(`Your want to edit ${row.first_name}`)
      },
      // handleDelete (index, row) {
      //   let indexToDelete = this.tableData.findIndex((tableRow) => tableRow.id === row.id)
      //   if (indexToDelete >= 0) {
      //     this.tableData.splice(indexToDelete, 1)
      //   }
      // },
      handleDelete (index, row) {
        // let indexToDelete = this.tableData.findIndex((tableRow) => tableRow.id === row.id)
        // if (indexToDelete >= 0) {
        //   this.tableData.splice(indexToDelete, 1)
        // }
        this.cancel_reminder_id = row.id
        this.$refs['my-modal'].show()
      },
      hideModal() {
        this.cancel_reminder_id = null
        this.$refs['my-modal'].hide()
      },
      handleView (index, row) {
        this.$router.push({
          path: '/admin/gestione-reminders/'+row.id
        })

      },
      getProgressReminder(slots) {
        let totalSlots = slots.length
        let progress = 0
        slots.map(el => {
          //if (new Date(el.date) < new Date()) {
            if ( el.sent === true && el.error === false ) {
                progress++
            }
        })
        return progress+"/"+totalSlots
      },
      getItems(data) {
        let totalSlots = Object.keys(data).length
        let progress = 0
        Object.keys(data).forEach(el => {
          if (data[el])
            progress++
        })
        return progress+"/"+totalSlots
      },
      foo(x) {
        console.log('foo',x)
      },
      async cancelReminder() {
        try {
          this.hasLoaded = false
          const ris = await ReminderService.updateReminder(this.cancel_reminder_id, {
            canceled: true
          })
          console.log('ris', ris)
          await this.fetchData()
        } catch(err) {
          console.log('err', err)
          this.error = err
        } finally {
          this.$refs['my-modal'].hide()
          this.hasLoaded = true
        }
      },
      async fetchData() {
        try {
          const reminders = await ReminderService.getReminders()
          console.log('my reminders', reminders)
          this.tableData = reminders.data.map(el => {
            let obj = {}
            obj.id = el.id
            obj.ended = el.closed ? 'Sì' : 'No'
            obj.start_date = el.start_date.split("T")[0].split("-").reverse().join("/")
            obj.template = el.template_in_progress.name
            obj.company = el.company.first_name + " " + el.company.last_name
            obj.progress = this.getProgressReminder(el.slots)
            //obj.progress = this.getProgressReminder(el.slots)
            obj.items_uploaded = this.getItems(el.data)
            obj.canceled = el.canceled
            return obj
          })
          this.tableData = this.tableData.filter( el => !el.canceled )
        } catch(err) {
          console.log('err', err)
        } finally {
          this.hasLoaded = true
        }
      }
    },
    async mounted () {
      this.fetchData()
    }
  }
</script>
<style>
</style>
